import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

// import htmlToPdf from './store/'
// import hljs from "highlight.js";
// import "highlight.js/styles/atom-one-dark.css" //样式
// import 'element-plus/dist/index.css'
import VueLazyload from 'vue3-lazy'
// 引入echarts
// import i18n from './locales/i18n'
// import '@/assets/fonts/font.css'
// import '@mekumiao/ssml-editor/style.css'

// import SSMLEditor from '@mekumiao/ssml-editor'

import wx from 'weixin-js-sdk';

const app = createApp(App);

// 添加到全局属性，这样可以在组件中使用 this.$wx 访问
app.config.globalProperties.$wx = wx;



// //创建v-highlight全局指令
// app.directive("highlight",function (el) {
//         const blocks = el.querySelectorAll("pre code");
//         blocks.forEach((block:any)=>{
//         hljs.highlightBlock(block)
//     })
// })
// .use(SSMLEditor)
createApp(App).use(store).use(router).use(autoAnimatePlugin).use(VueLazyload, {
  // 配置项
  loading:require('@/assets/new/0106d75ad7ea81a801213867320871.gif'),
  error:require('@/assets/new/0106d75ad7ea81a801213867320871.gif'),
})
.provide('$axios',axios).mount('#app')

