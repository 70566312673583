import axios from "axios";
import { ElMessage } from 'element-plus'
import { python } from './request'

import { onMounted, ref,nextTick, watch } from "vue";
import router from '@/router';
const request = axios.create({
  baseURL: python,
  timeout: 120000,
});
// 请求拦截器
let vipko=ref()
request.interceptors.request.use(
  (config) => {
 

    
     
    // config.headers['Cache-Control'] = 'public';
    // config.headers['Cache-Control'] = 'no-cache';
    // 检查是否有需要发送的数据
    // if (config.data) {
    //   // 创建FormData对象
    //   const formData = new FormData();
    //   // 遍历对象属性，添加到FormData中
    //   for (const key in config.data) {
    //     if (config.data.hasOwnProperty(key)) {
    //       formData.append(key, config.data[key]);
    //     }
    //   }
    // }

    // let token = localStorage.getItem('cc');

    // if (token&& token != 'undefined') { 
    //   // 设置请求的头信息
    //       config.headers['Authorization'] ="Bearer "+token; // 请求添加的token（此处也可不写）

    // }
  


    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);// 添加响应拦截器
request.interceptors.response.use(
  (response) => {

    if (response.data.status != "200" && response.data.status != "401"
     && response.data.status != "51314"
     && response.data.status != "51316"
     && response.data.status != "51315"
     && response.data.status != "51317"
     && response.data.status != "403"
     && response.data.status != "4321512"
     && response.data.status != "415135"
     && response.data.status != "252352"
   
     && response.data.status != "299"
     ) {
      ElMessage({
        message: '对不起，服务器出现异常，请重新刷新页面',
        type: 'error',
      });
    }

    if (response.data.status  == "401") {
      // 、、ElMessage({
      //   message: '未授权，请重新登录',
      //   type: 'error',
      // });
      localStorage.setItem("cc", "");
      localStorage.setItem("name", "");
      localStorage.setItem("haye", "");
      localStorage.setItem("toos", "");
      localStorage.setItem("user_id", "");
      localStorage.setItem("vip", "");
      localStorage.setItem("ret_url", "");
      router.push({
        path: "/login", //可以改成name
      });
    }
    if (response.data.status  == "51314") {
      ElMessage({
        message: response.data.message,
        type: 'error',
      });
    }
    if (response.data.status  == "415135") {
      let dasd = localStorage.getItem('grow_up')
      if (dasd == 3) {
          ElMessageBox.alert('当前网络繁忙，请稍后再试。', '提示', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: 'OK',
      
      })
      }
      if (dasd == 0) {
          ElMessageBox.alert('当前体验通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。', '提示', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: 'OK',
        callback: (action) => {
         
        },
      })
      }
      if (dasd == 1 || dasd == 2) {
          ElMessageBox.alert('当前通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。', '提示', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: 'OK',
        callback: (action) => {
       
        },
      })
      }
      if (dasd == 4) {
          ElMessageBox.alert('当前免费通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。', '提示', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: 'OK',
        callback: (action) => {
          
        },
      })
      }
    
    }
    if (response.data.status  == "252352") {
      ElMessage({
        message: '生成失败',
        type: 'error',
      });
    }
    if (response.data.status  == "299") {
      window.location.reload(); // 这将导致整个页面重新加载
      localStorage.setItem('asdaaad',2)
    }
    if (response.data.status  == "403") {
      window.location.reload(); // 这将导致整个页面重新加载
      localStorage.setItem('asdaaad',3)
    }
    if (response.data.status  == "51315") {
      ElMessage({
        message: response.data.message,
        type: 'error',
      });
    }
    if (response.data.status  == "51316") {
      ElMessage({
        message: response.data.message,
        type: 'error',
      });
    }
    if (response.data.status  == "51317") {
      ElMessage({
        message: response.data.message,
        type: 'error',
      });
    }
   return response.data;
  },
  (error) => {
  
   

    // 这里应该处理所有可能出现的错误
    if (error.response) {
      // 服务器返回了状态码
      if (error.response.status == "401") {
        // 处理401错误，比如跳转到登录页面或者提示重新登录
        localStorage.setItem("cc", "");
        localStorage.setItem("name", "");
        localStorage.setItem("haye", "");
        localStorage.setItem("toos", "");
        localStorage.setItem("user_id", "");
        localStorage.setItem("vip", "");
        localStorage.setItem("ret_url", "");
        let dasd = localStorage.getItem('dasd')
        if (dasd !=0) {
        //  、、  ElMessage({
        //   message: '未授权，请重新登录',
        //   type: 'error',
        // });
        localStorage.setItem('dasd',0)
        }
       
        router.push({
          path: "/login", //可以改成name
        }).then(() => {
          window.location.reload(); // 这将导致整个页面重新加载
          localStorage.setItem('dasd',1)
        }); 
           vipko.value ='false'
        return Promise.resolve(null); // 或者根据你的逻辑返回其他值
    
      } else if(error.response.status == "403"){
        window.location.reload(); // 这将导致整个页面重新加载
        localStorage.setItem('asdaaad',3)
       
      } else if(error.response.status == "299"){

        window.location.reload(); // 这将导致整个页面重新加载
        localStorage.setItem('asdaaad',2)
      }else{
        
        // 其他错误状态码处理
        ElMessage({
          message: `请求错误，状态码：${error.response.status}`,
          type: 'error',
        });
        // 返回一个空的Promise或者根据你的逻辑返回其他值
        return Promise.resolve(null); // 或者其他逻辑
      }
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      ElMessage({
        message: '请求没有收到响应',
        type: 'error',
      });
      return Promise.resolve(null); // 或者其他逻辑
    } else {
      // 触发请求错误
      ElMessage({
        message: '请求错误',
        type: 'error',
      });
      return Promise.resolve(null); // 或者其他逻辑
    }
    Promise.reject(error);
  }
);
export default request;


