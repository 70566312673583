
import  create from "@/uril/python.js";
export function magazine(params){
    //get 请求=》params
    return create({
        url:"/magazine/",
        method:"get",
        params
    })
}
export function smagazineeagetrch(params){
    //get 请求=》params
    return create({
        url:"/magazine/search/",
        method:"get",
        params
    })
}
export function smagazineasjkdch(params){
    //get 请求=》params
    return create({
        url:"/magazine/category/",
        method:"get",
        params
    })
}
export function magazineaudio(data){
    //get 请求=》params
    return create({
        url:"/magazine/audio/",
        method:"post",
        data:data
    })
}